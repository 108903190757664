@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap);
:root {
  --primary: #63dca0;
  --secondary: #cdf5a0;
  --error: #e7195a;
  --line-border-empty: #e0e0e0;

  --rdp-accent-color: var(--secondary);
  --rdp-background-color: #e7edff;
  --rdp-outline: 2px solid var(--rdp-accent-color);
}

body {
  background-color: white;
  color: black;
  font-size: small;
  height: 100%
}

.card {
  margin: 0;
}
.navigation {
  width: 100%;
}

.nav {
  justify-content: space-between;
  display: flex;
}

.nav-link p {
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 300;
  list-style: none;
}

.nav-link:hover {
  color: #2abb73;
}

li>.nav-link.active {
  border-bottom: 5px solid #2abb73;
}

a.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.navigation,
.off-canvas-navigation {
  background-color: white;
  z-index: 5;
  box-shadow: 0 5px 5px 0px rgba(130, 122, 122, 0.2)
}

.navigation>div {
  max-width: 100%;
}

.wrapper {
  position: relative;
  top: 0;
  /* height: 100vh; */
  overflow: hidden;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}

.panel-header {
  padding-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.btn {
  border-radius: 40px !important;
  /* -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); */
}

.btn-dark {
  background-color: gray;
}

.btn-lightGreen {
  background-color: #cdf5a0 !important; 
  border: 2px solid black !important;
  color: black !important;
}

.btn-darkGreen {
  background-color: #63dca0 !important;
  border: 2px solid black !important;
  color: black !important;
}

.body-display {
  width: 100% !important;
  height: '100vh';
  font-size: 0.6rem;
  line-height: 1.2;
  padding: 20px;
  color: black;
}

.starter-box-content {
  margin: auto;
  /* width: 95% !important; */
  /* background-color: white; */
  height: '100vh';
  /* color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); */
}

.form-input {
  padding: 20px;
  color: black;
}

.custom-page-header-parent {
  height: 100px;
  margin-right: 3rem;
  margin-left: 3rem;
}

.loader {
  border: 8px solid #d1d1d1;
  border-radius: 50%;
  border-top: 8px solid #63dca0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.buttonloader {
  border: 3px solid #7c7c7c;
  border-radius: 50%;
  border-top: 3px solid #63dca0;
  width: 20px;
  height: 20px;
  /* margin: 0 auto; */
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 300px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 150px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid gray;
}

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: gray;
}

.onboardingNumBox {
  width: 25px ; 
  background-color: #ededed;
  border-radius: 20%;
  text-align: center;
  justify-content:center
}

input[type='radio']:checked, input[type='checkbox']:checked{
  background: #63dca0;
}
/* Tablet */
@media only screen and (max-width: 1000px) {
  .headerMargin {
    margin-top: 40px;
  }
 
  .body-display {
    width: 800px !important;
    height: '100vh';
    font-size: 0.8rem;
    line-height: 1.5;
    padding: 0px 5px;
    color: black;
  }

  .starter-box-content {
    margin: auto;
    width: 90% !important;
    /* background-color: white; */
    height: '100vh';
    /* color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); */
  }

  .form-input {
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 20px;
    color: black;
  }
}

@media only screen and (max-width: 830px) {
  .container {
    margin-top: 10px;
    text-align: center;
    text-align: -webkit-center;
  }
  .headerMargin {
    margin-top: 30px;
  }
  li>.nav-link.active {
    border-bottom: 2px solid #2abb73 !important;
    border-top: transparent;
  }
  .nav-link {
    padding: 0px 10px 0px 10px !important;
    margin: 0;
    color: black;
    font-weight: 200;
    list-style: none;
  }
  .navigation {
    position: fixed;
    bottom: 5px;
    border-radius: 20px;
    background-color: #F9FDF3;
    margin: auto;
    padding: 10px  !important;
    width: 90%;
    justify-content: space-between;
  }
  .nav {
    display: flex;
    justify-content: space-around ;
  }
  .nav > li {
    margin: 0 ;
    display: inline-block;
  }

}

@media only screen and (max-width: 300px) {
  .body-display {
    width: 400px !important;
    height: '100vh';
    font-size: 0.8rem;
    line-height: 1.5;
    padding: 0px 5px;
    color: black;
  }

  .starter-box-content {
    margin: auto;
    width: 90% !important;
    /* background-color: white; */
    height: '100vh';
    /* color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); */
  }

  .form-input {
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 10px;
    color: black;
  }

}

.link-button {
  background-color: #6c757d;
  border: 1px solid transparent;
  border-radius: 10px !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  color: white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.link-button:hover {
  background-color: #565e64;
}

.link-button, .link-button:hover {
  text-decoration: none;
  color: white;
}

.stuff-details {
  background: #fff;
  border-radius: 10px;
  border: 1px solid grey;
  margin: 10px auto;
  padding: 15px;
  position: relative;
  /* box-shadow: 2px 2px 5px rgba(0,0,0,0.05); */
}
.stuff-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: #63dca0;
  color: var(--primary);
}
.stuff-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.stuff-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid #e7195a;
  border: 1px solid var(--error);
  color: #e7195a;
  color: var(--error);
  border-radius: 4px;
  margin: 0 30px;
}

.input-error {
  border: 1px solid #e7195a;
  border: 1px solid var(--error);
}

.lines {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
  color: #000;
}

.lines-background {
  background: #fff;
  padding: 0 10px;
}

.card-shadow {
  box-shadow: 0px 0px 0px -10px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.container {
  margin-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}

.progress-container::before {
  content: "";
  background: #e0e0e0;
  background: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 2px;
  width: 100%;
  z-index: -1;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 900px;
}

.progress {
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 4px;
  width: 0%;
  max-width: inherit;
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  background: #fff;
  color: #999;
  padding: 7px 15px;
  border: 2px solid #e0e0e0;
  border: 2px solid var(--line-border-empty);
  border-radius: 2rem;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .4s ease;
}

.circle.active {
  color: #000;
  background-color: #cdf5a0;
  background-color: var(--secondary);
  border: 2.5px solid #000;
  font-weight: 700;
  font-size: 12px;
}

.container.responsive {
  margin: 30px;
}

.progress-container.responsive::before {
  background: 'transparent'
}

.progress-container.responsive {
  margin-bottom: 0px;
  max-width: 100%;
  width: 0px;
  margin-right: 200px;
}

.progress.responsive {
  background: 'transparent';
}

.circle.responsive {
  background: #e0e0e0;
  background: var(--line-border-empty);
  margin: 4px;
  padding: 4px 30px;
  border-radius: 2rem;
  border: 0px;
}

.circle.active.responsive {
  background-color: #cdf5a0;
  background-color: var(--secondary);
}

.back-btn {
  background-color: transparent;
  color: black;
  border: 2px solid #000;
  border-radius: 2rem;
  width: 110px;
  max-width: 100%;
  height: 40px;
  font-weight: 600;
}

.back-btn:hover {
  cursor: pointer;
  background-color: #cdf5a0;
  background-color: var(--secondary);
}

.continue-btn {
  background-color: #cdf5a0;
  color: black;
  border-radius: 2rem;
  border: 2px solid #000;
  font-weight: 600;
}

.previous-btn {
  background-color: transparent;
  color: black;
  border: 2px solid #000;
  border-radius: 2rem;
  font-weight: 600;
}

.previous-btn:hover {
  cursor: pointer;
  background-color: #cdf5a0;
  background-color: var(--secondary);
}

.mapMarker {
  width: 20px;
  height: 20px;
  color: white;
  font-size: inherit;
  background: linear-gradient(120deg, #fdae18 0, #cdf5a0 25%, #63dca0 50%, #cdf5a0 75%, #63dca0 100%);
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px inset #172b4d;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.mapMarker::after {
  content: '';
  border: 1px solid #172b4d;
  position: relative;
  top: 16px;
  height: 12px;
}

.parent-map {
  position: relative;
}

.autocomplete {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  /* width: 600px; */
  max-width: 100%;
}

.pac-container {
  z-index: 10000 !important;
}

.rdp-day_today:not(.rdp-day_outside) {
  color: red;
}

.rdp-button:focus,
.rdp-button:active {
  color: inherit;
  border: transparent;
  background-color: transparent;
}

.rdp-button:hover:not([aria-disabled='true']) {
  background-color: rgb(205,245,160, 0.3);
}

.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
  color: black;
  font-weight: 800;
  border: 2px solid #000;
  background-color: #cdf5a0;
  background-color: var(--rdp-accent-color);
}

.modal-dialog.modal-half {
  max-width: 55%;
  margin: 1.75rem auto;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.subscription-details {
  border-radius: 10px;
  border: 1px solid grey;
}

.subscription-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.subscription-details h5 {
  color: #555;
}
.subscription-details h4 {
  font-size: 1.2em;
  color: #555;
}
.plan-details {
  font-size: 1em; 
  color:#555;
}
.floating-button {
  background-color: #cdf5a0;
  background-color: var(--secondary);
  border: 1px solid #000;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  position: absolute;
  bottom: 60px;
  right: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
}
.floating-button-add {
  width: 20px;
  margin-left:auto;
  margin-right:auto;
}


